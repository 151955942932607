import service from "@/utils/request"; // @Tags supplierList
// @Summary 用供应商名称查询supplierList
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body model.supplierList true "用供应商名称查询supplierList"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"查询成功"}"
// @Router /funds/supplier/list [get]

export var getSupplierList = function getSupplierList(params) {
  return service({
    url: "/funds/supplier/list",
    method: 'get',
    params: params
  });
}; // @Tags supplierRecord
// @Summary 用id查询supplierRecord
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body model.supplierRecord true "用id查询supplierRecord"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"查询成功"}"
// @Router /funds/supplier/deposit_record [get]

export var getSupplierRecord = function getSupplierRecord(params) {
  return service({
    url: "/funds/supplier/deposit_record",
    method: 'get',
    params: params
  });
}; // @Tags systrm
// @Summary 供应商余额充值
// @Security ApiKeyAuth
// @Produce  application/json
// @Success 200 {string} string "{"success":true,"data":{},"msg":"返回成功"}"
// @Router /system/getsupplierDeposit [post]

export var createSupplierDeposit = function createSupplierDeposit(data) {
  return service({
    url: "/funds/supplier/deposit",
    method: "post",
    data: data
  });
};